import Link from "next/link";
import Image from "next/image";
import Newsletter from "../newsletter";

export default function Footer2() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-fa331a9 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="fa331a9"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-b16946f"
          data-id="b16946f"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-fdb29d5 elementor-widget elementor-widget-image"
              data-id="fdb29d5"
              data-element_type="widget"
              data-widget_type="image.default"
            >
              <div className="elementor-widget-container">
                <Link href="/" title="IS Auditr">
                  <Image
                    width={225}
                    height={125}
                    loading="lazy"
                    alt="IS Auditr Logo"
                    src="/images/logo.svg"
                    className="attachment-large size-large"
                    style={{
                      width: "30%",
                      height: "auto",
                    }}
                  />
                </Link>
              </div>
            </div>

            <div
              className="elementor-element elementor-element-1d59e17 elementor-widget elementor-widget-text-editor"
              data-id="1d59e17"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                Expert consultants, simplified and customized business solutions
                and a business upgrade is the only package we provide.
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-7857a46"
          data-id="7857a46"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-b652257 elementor-widget elementor-widget-heading"
              data-id="b652257"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h4 className="elementor-heading-title elementor-size-default">
                  quick links
                </h4>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-514c6e8 elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu"
              data-id="514c6e8"
              data-element_type="widget"
              data-settings='{"layout":"vertical","submenu_icon":{"value":"&lt;i className=\"fas fa-caret-down\"&gt;&lt;\/i&gt;","library":"fa-solid"}}'
              data-widget_type="nav-menu.default"
            >
              <div className="elementor-widget-container">
                <nav className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-none">
                  <ul
                    id="menu-1-514c6e8"
                    className="elementor-nav-menu sm-vertical"
                  >
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-995">
                      <Link
                        href="/"
                        title="Home"
                        aria-current="page"
                        className="elementor-item elementor-item-active"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-998">
                      <Link
                        href="/services/"
                        title="Services"
                        className="elementor-item"
                      >
                        Services
                      </Link>
                    </li>
                    {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-999">
                      <Link href="/work/" title="Work">
                        <a className="elementor-item">Work</a>
                      </Link>
                    </li> */}
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-996">
                      <Link
                        href="/about-us/"
                        title="About Us"
                        className="elementor-item"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-996">
                      <Link
                        href="/careers/"
                        title="Careers"
                        className="elementor-item"
                      >
                        Careers
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-997">
                      <Link
                        href="/contact-us/"
                        title="Contact Us"
                        className="elementor-item"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </nav>
                <div
                  className="elementor-menu-toggle"
                  role="button"
                  tabIndex="0"
                  aria-label="Menu Toggle"
                  aria-expanded="false"
                >
                  <i
                    aria-hidden="true"
                    role="presentation"
                    className="elementor-menu-toggle__icon--open eicon-menu-bar"
                  ></i>
                  <i
                    aria-hidden="true"
                    role="presentation"
                    className="elementor-menu-toggle__icon--close eicon-close"
                  ></i>
                  <span className="elementor-screen-only">Menu</span>
                </div>
                <nav
                  className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                  aria-hidden="true"
                >
                  <ul
                    id="menu-2-514c6e8"
                    className="elementor-nav-menu sm-vertical"
                  >
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-995">
                      <Link
                        href="/"
                        title="Home"
                        tabIndex="-1"
                        aria-current="page"
                        className="elementor-item elementor-item-active"
                      >
                        Home
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-998">
                      <Link
                        tabIndex="-1"
                        title="Services"
                        href="/services/"
                        className="elementor-item"
                      >
                        Services
                      </Link>
                    </li>
                    {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-999">
                      <Link href="/work/" title="Work">
                        <a className="elementor-item" tabIndex="-1">
                          Work
                        </a>
                      </Link>
                    </li> */}
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-996">
                      <Link
                        tabIndex="-1"
                        title="About Us"
                        href="/about-us/"
                        className="elementor-item"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-997">
                      <Link
                        tabIndex="-1"
                        title="Contact Us"
                        href="/contact-us/"
                        className="elementor-item"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-7e305e6"
          data-id="7e305e6"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-7e24d33 elementor-widget elementor-widget-heading"
              data-id="7e24d33"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h4 className="elementor-heading-title elementor-size-default">
                  Contact Info
                </h4>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-2ca3538 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="2ca3538"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items">
                  {/* <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-text">
                      Sector - 68, Mohali, Punjab 160062
                    </span>
                  </li> */}
                  <li className="elementor-icon-list-item">
                    <a href="mailto:hello@isauditr.com">
                      <span className="elementor-icon-list-text">
                        hello@isauditr.com
                      </span>
                    </a>
                  </li>
                  <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-text">
                      +91 97188 35704
                    </span>
                  </li>
                  {/* <li className="elementor-icon-list-item">
                    <span className="elementor-icon-list-text">
                      Mon-Fri 9am-6pm
                    </span>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Newsletter />
      </div>
    </section>
  );
}
