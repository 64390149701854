import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";

export default function Newsletter() {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async (values) => {
      const res = await fetch("/api/email", {
        method: "POST",
        body: JSON.stringify(values),
      });

      const response = await res.json();

      if (res && res.ok) {
        if (response.message) toast.success(response.message);
        return response;
      } else {
        if (response.message) toast.error(response.message);
        return res.ok;
      }
    },
  });

  return (
    <div
      className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-cf4661b"
      data-id="cf4661b"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-047e017 elementor-widget elementor-widget-heading"
          data-id="047e017"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <h4 className="elementor-heading-title elementor-size-default">
              join our Newsletter
            </h4>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-62eed51 elementor-widget elementor-widget-text-editor"
          data-id="62eed51"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            Sign up for our newsletter to enjoy free marketing tips,
            inspirations, and more.
          </div>
        </div>
        <div
          className="elementor-element elementor-element-ab704fa elementor-button-align-end elementor-mobile-button-align-stretch elementor-widget elementor-widget-form"
          data-id="ab704fa"
          data-element_type="widget"
          data-settings='{"step_next_label":"Next","step_previous_label":"Previous","button_width":"40","button_width_tablet":"30","step_type":"number_text","step_icon_shape":"circle"}'
          data-widget_type="form.default"
        >
          <div className="elementor-widget-container">
            <form className="elementor-form" onSubmit={formik.handleSubmit}>
              <div className="elementor-form-fields-wrapper elementor-labels-">
                <div className="elementor-field-type-email elementor-field-group elementor-column elementor-field-group-email elementor-col-60 elementor-md-70 elementor-field-required">
                  <label
                    htmlFor="nw-form-field-email"
                    className="elementor-field-label elementor-screen-only"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="nw-form-field-email"
                    className="elementor-field elementor-size-md  elementor-field-textual"
                    placeholder="Email Address*"
                    required="required"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div
                      role="alert"
                      className="elementor-message elementor-message-danger"
                    >
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="elementor-field-group elementor-column elementor-field-type-submit elementor-col-40 e-form__buttons elementor-md-30">
                  <button
                    type="submit"
                    className="elementor-button elementor-size-md"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    <span>
                      {formik.isSubmitting ? (
                        <span className="elementor-button-text elementor-form-spinner">
                          <i className="fa fa-spinner fa-spin"></i>
                          &nbsp;
                        </span>
                      ) : (
                        <>
                          <span className="elementor-button-icon"></span>
                          <span className="elementor-button-text">
                            Sign Me Up
                          </span>
                        </>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
